@use "variables" as *;
@use "mixins" as *;

body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: $font-family !important;
    background: $background-colour;
    color: $text-colour;
}

.form-control {
    @include reset-font;

    &:focus {
        border: 0.05rem solid $primary-colour;
    }
}

.vertical-center {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.btn-primary {
    @include reset-colours-and-fonts;
    color: whitesmoke !important;
}

.btn-secondary {
    @include reset-colours-and-fonts;
    color: $text-colour !important;
    background-color: $accent-colour !important;
}

.btn-outline-secondary {
    @include reset-colours-and-fonts;
    color: $primary-colour!important;
    background-color: $background-colour !important;
}

.error {
    color: $error-colour;
}

.success {
    color: $success-colour;
}

.warning {
    color: $warning-colour;
}

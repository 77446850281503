@use "variables" as *;

@mixin reset-font {
    color: $text-colour !important;
    font-family: $font-family !important;
}

@mixin reset-colours-and-fonts {
    @include reset-font;

    background: $primary-colour !important;
}

@mixin xs {
    @media (max-width: 575px) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@use "sass:color";

$primary-colour: color.scale(#5c74ef, $lightness: -10%);
$accent-colour: #0cb7fa;
$background-colour: #FBFBFF;
$text-colour: #1b1608;
$error-colour: #db3630;
$success-colour: #31db6d;
$warning-colour: orange;
$font-family: 'Quicksand', sans-serif;

@use "../../mixins" as *;
@use "../../variables" as *;

@mixin nav-colours {
    background-color: rgba(white, 0.17);
    color: rgba($text-colour, 0.7) !important;
}

.nav-item {
    min-width: fit-content;
}

.main-menu {
    @include reset-colours-and-fonts;
}

.main-menu-link {
    @include reset-font;
    color: whitesmoke !important;
}

.nav-link.active {
    @include nav-colours;
}

.main-menu-link.dropdown-item {
    color: whitesmoke !important;

    &.active {
        @include nav-colours;
    }

    &:hover {
        @include nav-colours;
    }
}

.dropdown-menu {
    background-color: $primary-colour !important;
    margin-top: 0.5rem !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

#main-menu-left {
    @include md {
        float: left;
        width: 60%;
        margin-left: 1rem;
    }

    @include lg {
        float: left;
        width: 70%;
        margin-left: 5rem;
    }
}

#main-menu-right {
    @include md {
        float: right;
        width: 40%;
        margin-right: 1rem;
    }

    @include lg {
        float: right;
        width: 30%;
        margin-right: 5rem;
    }
}

